import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import Notifications from './Notifications';

const Dashboard = () => {

  return (
    <div>
      <div class="wrapper">
        {/* Sidebar */}
        <Sidebar />
        {/* Sidebar Ende */}
        <div class="main-panel">
          <Header />
          <div class="content">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h2 class="card-title">Meldungen</h2>
                  </div>
                  <div class="card-body">
                      <Notifications />
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  );
};

export default Dashboard;
