
/* EDITCLIENT */
/* Hier werden alle Daten zu einem ausgewählten Client angezeigt bzw. bearbeitet */
/* Der Clientname wird mit der URL (GET) mitgeliefert */


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header'; 
import Sidebar from './Sidebar';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js'; // Importiere die benötigten Komponenten
import LoadingScreen from './LoadingScreen';
import $ from 'jquery'; // Import jQuery für Bootstrap Notify
import 'bootstrap-notify'; // Import Bootstrap Notify

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const EditClient = () => {
    const { client_name } = useParams();
    const [clientData, setClientData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [cpuWarning, setCpuWarning] = useState(''); // Warnwert für CPU
    const [ramWarning, setRamWarning] = useState(''); // Warnwert für RAM
    const [diskWarning, setDiskWarning] = useState(''); // Warnwert für Disk
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [cpuData, setCpuData] = useState([]);
    const [ramData, setRamData] = useState([]);
    const [networkData, setNetworkData] = useState([]);
    const [cpuModel, setCpuModel] = useState('');
    const [gpuModel, setGpuModel] = useState('');
    const [osVersion, setOsVersion] = useState('');
    const [osName, setOsName] = useState('');
    const [osStatus, setOsStatus] = useState('');
    const [drives, setDrives] = useState([]);
    const [services, setServices] = useState([]);
    const [networkAdapters, setNetworkAdapters] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    /* Hier wird beim Aufruf der Seite ausgeführt */
    useEffect(() => {
        /* Hier werden die Daten des ausgewählten Clients geladen */
        const fetchClientData = async () => {
            const response = await fetch(`https://api.fl-itservice.de/edit.php?client=${client_name}`);
            if (response.ok) {
                const data = await response.json();
                setClientData(data);
                setSelectedCustomer(data.customer_id); // Setze den aktuellen Kunden
                setSelectedCategory(data.categorie); // Setze die aktuelle Kategorie
                setCpuWarning(data.cpu_warning || ''); // Setze den aktuellen Warnwert für CPU
                setRamWarning(data.ram_warning || ''); // Setze den aktuellen Warnwert für RAM
                setDiskWarning(data.disk_warning || ''); // Setze den aktuellen Warnwert für Disk
                setCpuModel(data.cpu_model || '');
                setGpuModel(data.gpu_model || '');
                setDrives(JSON.parse(data.drives || '[]'));
                setServices(JSON.parse(data.services || '[]'));
                setNetworkAdapters(JSON.parse(data.network_adapter || '[]'));
                setOsVersion(data.os_version || '');
                setOsName(data.os_name || '');
                setOsStatus(data.os_status || '');
            } else {
                console.error('Fehler beim Abrufen der Client-Daten:', response.statusText);
            }
            setLoading(false);
        };

        /* Hier werden die Kunden und Kategorien geladen */
        const fetchCustomers = async () => {
            const response = await fetch('https://api.fl-itservice.de/get_customers.php');
            if (response.ok) {
                const data = await response.json();
                setCustomers(data);
            } else {
                console.error('Fehler beim Abrufen der Kunden:', response.statusText);
            }
        };

        const fetchCategories = async () => {
            const response = await fetch('https://api.fl-itservice.de/get_categories.php');
            if (response.ok) {
                const data = await response.json();
                setCategories(data);
            } else {
                console.error('Fehler beim Abrufen der Kategorien:', response.statusText);
            }
        };

        /* Hier werden die ständigen Daten geladen */
        const fetchMetrics = async () => {
            const response = await fetch('https://api.fl-itservice.de/metrics.php'); // Alle Metriken abrufen
            if (response.ok) {
                const data = await response.json();
                const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000); // Zeitstempel für eine Stunde zurück

                // Filtern der CPU-Daten für den spezifischen Client und innerhalb der letzten Stunde
                const filteredData = data.filter(metric => 
                    metric.client_name === client_name && 
                    new Date(metric.timestamp) >= oneHourAgo
                );

                setCpuData(filteredData.map(metric => ({ timestamp: metric.timestamp, cpu_usage: metric.cpu_usage }))); // CPU-Daten speichern
                setRamData(filteredData.map(metric => ({ timestamp: metric.timestamp, ram_usage: metric.memory_used }))); // RAM-Daten speichern
                setNetworkData(filteredData.map(metric => ({ timestamp: metric.timestamp, network_in: metric.network_in, network_out: metric.network_out }))); // Network-Daten speichern
            } else {
                console.error('Fehler beim Abrufen der CPU-Daten:', response.statusText);
            }
        };


        fetchClientData();
        fetchCustomers();
        fetchCategories();
        fetchMetrics();
        const interval = setInterval(fetchMetrics, 10000); // Aktualisierung alle 10 Sekunden
        return () => clearInterval(interval);
    }, [client_name]);

    /* Hier werden die Daten in die Komponente übergeben, wenn die Daten gespeichert werden */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(`https://api.fl-itservice.de/edit.php?client=${client_name}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                customer_id: selectedCustomer, 
                categorie_name: selectedCategory,
                cpu_warning: cpuWarning, 
                ram_warning: ramWarning,
                disk_warning: diskWarning 
            }),
        });

        if (response.ok) {
            $.notify({
                icon: "tim-icons icon-check-2",
                message: "Clientdaten wurden erfolgreich gespeichert."
            }, {
                type: 'success',
                timer: 3000,
                placement: {
                    from: "top",
                    align: "center"
                }
            });
        } else {
            $.notify({
                icon: "tim-icons icon-alert-circle-exc",
                message: "Fehler beim speichern der Clientdaten."
            }, {
                type: 'danger',
                timer: 3000,
                placement: {
                    from: "top",
                    align: "center"
                }
            });
        }
    };

    /* Wenn der Button Client löschen geklickt wurde */
    const handleDeleteClient = async () => {
        const response = await fetch(`https://api.fl-itservice.de/delete.php?client=${client_name}`, {
            method: 'DELETE',
        });

        if (response.ok) {
            $.notify({
                icon: "tim-icons icon-check-2",
                message: "Client wurde erfolgreich gelöscht."
            }, {
                type: 'success',
                timer: 3000,
                placement: {
                    from: "top",
                    align: "center"
                }
            });
        } else {
            $.notify({
                icon: "tim-icons icon-alert-circle-exc",
                message: "Client konnte nicht gelöscht werden."
            }, {
                type: 'danger',
                timer: 3000,
                placement: {
                    from: "top",
                    align: "center"
                }
            });
        }
    };

    if (loading) {
        return <div><LoadingScreen /></div>;
    }

    if (!clientData) {
        return <div>Client nicht gefunden</div>;
    }

    // Daten für das CPU-Diagramm vorbereiten
    const cpuChartData = {
        labels: cpuData.map(data => new Date(data.timestamp).toLocaleTimeString()), // Zeitstempel als Labels
        datasets: [
            {
                label: 'CPU Usage (%)',
                data: cpuData.map(data => data.cpu_usage), // CPU-Nutzungsdaten
                borderColor: 'rgba(75,192,192,1)',
                backgroundColor: 'rgba(75,192,192,0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    // Daten für das RAM-Diagramm vorbereiten
    const ramChartData = {
        labels: ramData.map(data => new Date(data.timestamp).toLocaleTimeString()), // Zeitstempel als Labels
        datasets: [
            {
                label: 'RAM Usage (%)',
                data: ramData.map(data => data.ram_usage), // CPU-Nutzungsdaten
                borderColor: 'rgba(75,192,192,1)',
                backgroundColor: 'rgba(75,192,192,0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    // Daten für das Network-Diagramm vorbereiten
    const networkChartData = {
        labels: networkData.map(data => new Date(data.timestamp).toLocaleTimeString()), // Zeitstempel als Labels
        datasets: [
            {
                label: 'Download (MB/s)',
                data: networkData.map(data => data.network_in), // CPU-Nutzungsdaten
                borderColor: 'rgb(16, 230, 33)',
                backgroundColor: 'rgba(75,192,192,0.2)',
                fill: true,
                tension: 0.4,
            },
            {
                label: 'Upload (MB/s)',
                data: networkData.map(data => data.network_out), // CPU-Nutzungsdaten
                borderColor: 'rgb(230, 59, 16)',
                backgroundColor: 'rgba(75,192,192,0.2)',
                fill: true,
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
    };

    // Filtere die Dienste basierend auf dem Suchbegriff
    const filteredServices = services.filter(service =>
        service.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <div class="wrapper">
            {/* Sidebar */}
            <Sidebar />
            {/* Sidebar Ende */}
            <div class="main-panel">
            <Header />
            <div class="content">
                <div class="d-flex justify-content-betwenn">
                    <div class="col-md-6">
                    <h2>
                        {client_name} | {selectedCategory}
                    </h2>
                    </div>
                    <div class="col-md-6">
                        <button class="btn-sm btn-primary float-right">Client Neustart</button>
                        {/* Button zum Öffnen des Modals */}
                        <button className="btn-sm btn-primary float-right" onClick={() => setShowModal(true)}>
                            Dienste anzeigen ({services.length}) {/* Anzahl der Dienste im Button anzeigen */}
                        </button>
                        {/* Client details and edit form here */}
                        <button onClick={handleDeleteClient} className="btn-sm btn-primary float-right">Client Löschen</button>
                    </div>
                </div>
            <div class="row">
                <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-title">OS Informationen</h2>
                            </div>
                            <div class="card-body">
                                <table class="table">
                                    <tbody>
                                    <tr>
                                        <td>OS</td>
                                        <td>{osName}</td>
                                    </tr>
                                    <tr>
                                        <td>OS Version</td>
                                        <td>{osVersion}</td>
                                    </tr>
                                    <tr>
                                        <td>OS Lizenzstatus</td>
                                        <td>{osStatus}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
                <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-title">Hardwareinformationen</h2>
                            </div>
                            <div class="card-body">
                                <table class="table">
                                    <tbody>
                                    <tr>
                                        <td>CPU Model</td>
                                        <td>{cpuModel}</td>
                                    </tr>
                                    <tr>
                                        <td>GPU Model</td>
                                        <td>{gpuModel}</td>
                                    </tr>
                                    <tr>
                                        <td>Festplatten</td>
                                        <td>{drives}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
                <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-title">Netzwerk</h2>
                            </div>
                            <div class="card-body">
                                <table class="table">
                                    <tbody>
                                    {networkAdapters.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.AdapterName}</td>
                                        <td>{data.IPv4Address}</td>
                                        <td>{data.SubnetMask}</td>
                                        <td>{data.Gateway}</td>
                                    </tr>
                                     ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-title">CPU Auslastung</h2>
                            </div>
                            <div class="card-body">
                                <Line data={cpuChartData} options={options} />
                            </div>
                        </div>
                </div>
                <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-title">RAM Auslastung</h2>
                            </div>
                            <div class="card-body">
                                <Line data={ramChartData} options={options} />
                            </div>
                        </div>
                </div>
                <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <h2 class="card-title">Netzwerk Traffic</h2>
                            </div>
                            <div class="card-body">
                                <Line data={networkChartData} options={options} />
                            </div>
                        </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                <div class="card">
                <div class="card-header">
                    <div class="row">
                      <div class="col-sm-12">
                        <h5 class="card-category">Client {client_name}</h5>
                        <h2 class="card-title">Einstellungen</h2>
                      </div>
                    </div>
                </div>
                <div class="card-body">
                <form onSubmit={handleSubmit}>
                    <div class="form-group">
                        <label>Kunde:</label>
                        <select class="form-control" value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.target.value)}>
                            {customers.map(customer => (
                                <option key={customer.id} value={customer.id}>{customer.name}</option>
                            ))}
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Kategorie:</label>
                        <select class="form-control" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                            {categories.map(category => (
                                <option class="form-option" key={category.id} value={category.name}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                    <div class="form-group">
                        <label>CPU Warnwert (%):</label>
                        <input
                            type="number"
                            value={cpuWarning}
                            onChange={(e) => setCpuWarning(e.target.value)}
                            class="form-control"
                            required
                        />
                    </div>
                    <div class="form-group">
                        <label>RAM Warnwert (%):</label>
                        <input
                            type="number"
                            value={ramWarning}
                            onChange={(e) => setRamWarning(e.target.value)}
                            class="form-control"
                            required
                        />
                    </div>
                    <div class="form-group">
                        <label>Disk Warnwert (%):</label>
                        <input
                            type="number"
                            value={diskWarning}
                            onChange={(e) => setDiskWarning(e.target.value)}
                            class="form-control"
                            required
                        />
                    </div>
                    <button type="submit" class="btn btn-primary">Änderungen speichern</button>
                    {error && <div>{error}</div>}
                    {success && <div>{success}</div>}
                </form>
                </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    </div>
    
    {/* Modal für die Dienste */}
    {showModal && (
        <div className="modal" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-scrollable modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Dienste</h5>
                        <button type="button" className="close" onClick={() => setShowModal(false)}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/* Suchfeld hinzufügen */}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nach Dienst suchen..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Dienstname</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredServices.map((service, index) => (
                                    <tr key={index}>
                                        <td>{service}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                            Schließen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )}

    </div>    
    );
};

export default EditClient;