import React, { useEffect, useState } from 'react';
import Header from './Header'; 
import Sidebar from './Sidebar';


const MetricsTable = () => {
  const [metrics, setMetrics] = useState([]);
  const [filter, setFilter] = useState(''); // Zustand für den Filter
  const [selectedClient , setSelectedClient] = useState(null); // Zustand für den ausgewählten Client


  useEffect(() => {
    const fetchMetrics = async () => {
      const response = await fetch('https://api.fl-itservice.de/metrics.php');
      const data = await response.json();
      setMetrics(data);
    };

    fetchMetrics();
    const interval = setInterval(fetchMetrics, 10000); // Aktualisierung alle 10 Sekunden
    return () => clearInterval(interval);
  }, []);

  // Finde den letzten Eintrag für jeden Client
  const latestMetrics = metrics.reduce((acc, metric) => {
    const { client_name } = metric;
    if (!acc[client_name] || new Date(metric.timestamp) > new Date(acc[client_name].timestamp)) {
      acc[client_name] = metric;
    }
    return acc;
  }, {});

  const latestMetricsArray = Object.values(latestMetrics);

  // Status basierend auf dem letzten Zeitstempel bestimmen
  const getStatus = (timestamp) => {
    const now = new Date();
    const lastContact = new Date(timestamp);
    const diffInSeconds = (now - lastContact) / 1000;
    return diffInSeconds < 30 ? 'Online' : 'Offline';
  };

  // Funktion zum Bestimmen der Statusfarbe
  const getStatusColor = (status) => {
    return status === 'Online' ? 'green' : 'red';
  };

  // Funktion zum Formatieren des Zeitstempels in deutscher Zeit (inkl. CET/CEST)
  const formatDateToGermanTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      timeZone: 'Europe/Berlin',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',  // Optional: Sekundengenauigkeit hinzufügen
    };
    return date.toLocaleString('de-DE', options);
  };

  // Filterlogik: filtert nach Client-Name oder IP (local/public)
  const filteredMetrics = latestMetricsArray.filter(metric => {
    return (
      metric.client_name.toLowerCase().includes(filter.toLowerCase()) ||
      metric.local_ip.includes(filter) ||
      metric.public_ip.includes(filter) ||
      metric.customer_name.includes(filter)
    );
  });

// Aktuelles Datum und Uhrzeit
const now = new Date();

// Filtere die Metriken nach dem ausgewählten Client und innerhalb der letzten Stunde
const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000); // Eine Stunde zurück
const ModalMetrics = metrics.filter(m => 
  m.client_name === selectedClient && 
  new Date(m.timestamp) >= oneHourAgo
);


  return (
    <div>
      <div class="wrapper">
        {/* Sidebar */}
        <Sidebar />
        {/* Sidebar Ende */}
        <div class="main-panel">
          <Header />
        <div class="content">

          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                    <div class="row">
                      <div class="col-sm-3">
                        <h2 class="card-title">Verbundene Agents</h2>
                        {/* Filter-Eingabefeld */}
                        <div className="filter-container mb-3">
                          <input
                            type="text"
                            placeholder="Filter nach Client-Name oder Kunde"
                            className="form-control"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)} // Aktualisiere den Filterzustand
                          />
                        </div>
                      </div>
                    </div>
                </div>
                <div class="card-body">
                  <table class="table tablesorter">
                    <thead>
                      <tr>
                        <th>Client Name</th>
                        <th>Benutzer</th>
                        <th>Kunde</th>
                        <th>Kategorie</th>
                        <th>Local IP</th>
                        <th>Public IP</th>
                        <th>CPU (%)</th>
                        <th>RAM (%)</th>
                        <th>Festplatten (%)</th>
                        <th>letzter Kontakt</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredMetrics.length > 0 ? (
                        filteredMetrics.map((metric, index) => (
                          <tr key={index}>
                            <td>
                              <a href={`/edit/${metric.client_name}`}>
                                {metric.client_name}
                              </a>
                            </td>
                            <td>{metric.user}</td>
                            <td>{metric.customer_name || 'Kein Kunde'}</td> {/* Kundenzuordnung */}
                            <td>{metric.category_name || 'Keine Kategorie'}</td>
                            <td>{metric.local_ip}</td>
                            <td>{metric.public_ip}</td>
                            <td>{metric.cpu_usage}</td>
                            <td>{metric.memory_used}</td>
                            <td>{metric.disk_usage}</td>
                            <td>{formatDateToGermanTime(metric.timestamp)}</td>
                            <td style={{ color: getStatusColor(getStatus(metric.timestamp)) }}>{getStatus(metric.timestamp)}</td> {/* Status mit Farbe */}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12">Keine Daten gefunden</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
                
              
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default MetricsTable;
