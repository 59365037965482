import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Navigate } from 'react-router-dom';
import MetricsTable from './MetricsTable';  // Tabelle mit allen Metriken
import EditClient from './EditClient';
import Login from './Login';
import Register from './Register';
import AddCustomer from './AddCustomer';
import CustomerTable from './CustomerTable';
import Notifications from './Notifications';
import Dashboard from './Dashboard';
import Cronjobs from './Cronjobs';


const App = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Überprüfe, ob ein Token im Local Storage vorhanden ist
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (username) => {
    setIsAuthenticated(true); // Benutzer als authentifiziert markieren
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Token aus dem Local Storage entfernen
    setIsAuthenticated(false); // Benutzer als nicht authentifiziert markieren
  };

  return (
    <Router>
      <div>
        <Routes>
          {/* Route für die Tabelle */}
          <Route path="/" element={isAuthenticated ? <Dashboard /> : <Login onLogin={handleLogin} />} />
          <Route path="/clients" element={isAuthenticated ? <MetricsTable /> : <Login onLogin={handleLogin} />} />
          <Route path="/logout" element={<Logout onLogout={handleLogout} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/edit/:client_name" element={isAuthenticated ? <EditClient /> : <Login onLogin={handleLogin} />} />
          <Route path="/add-customer" element={isAuthenticated ? <AddCustomer /> : <Login onLogin={handleLogin} />} />
          <Route path="/customers" element={isAuthenticated ? <CustomerTable /> : <Login onLogin={handleLogin} />} />
          <Route path="/notifications" element={isAuthenticated ? <Notifications /> : <Login onLogin={handleLogin} />} />
          <Route path="/cronjobs" element={isAuthenticated ? <Cronjobs /> : <Login onLogin={handleLogin} />} />

        </Routes>
      </div>
    </Router>
  );
};

// Neue Logout-Komponente
const Logout = ({ onLogout }) => {
  useEffect(() => {
    onLogout(); // Logout-Funktion aufrufen
  }, [onLogout]);

  return <Navigate to="/" />; // Nach dem Logout zur Startseite weiterleiten
};

export default App;
